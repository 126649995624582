import React, { useEffect, useState } from "react";
//conexion con el context global para obtener los datos de la base de datos
import axios from 'axios';
import { GlobalContext } from './components/data/GlobalContext';

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Gallery from "./pages/Gallery";
import Contact from "./pages/Contact";
import ServicesDetalle from "./components/services/ServicesDetalle";


function App() {
  const [dbData, setdbData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const idApi = '6320f07d48dc3dbded2db95e'
  const urlServer = 'https://paginasserver.herokuapp.com/api/';
  const urlApi = `${urlServer}paginas/${idApi}`;

  // global context data
  useEffect(() => {

    const fetchData = async () => {
      setIsLoading(true);
      const result = await axios(urlApi);
      if (result.data) {
        setdbData(result.data);
        setIsLoading(false);
      }
      else {
        setdbData({});
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  // verificar los datos del context
  if (isLoading) return (
    <div className='d-flex justify-center align-items-center'>
      <div className='d-flex align-items-center justify-center'>
        <div className='inline-block w-8 h-8 border-4 rounded-full spinner-border animate-spin' role='status' style={{ color: 'transparent' }}>
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
      <p style={{ color: 'transparent' }}>Loading...</p>
    </div>)
  else
    return (
      <GlobalContext.Provider value={dbData}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/contact" element={<Contact />} />
            {/* <Route path="/:id" element={<ServicesDetalle />} /> */}
          </Routes>
        </BrowserRouter>
      </GlobalContext.Provider>

    );
}

export default App;